<template>
    <v-list dense class="menu-items">
        <template v-for="item in items">
            <v-list-group v-if="item.children" :key="item.text">
                <template v-slot:activator>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                </template>

                <v-list-item
                    v-for="(child, i) in item.children"
                    :key="i"
                    class="blue-grey lighten-5">
                    <!--Grand child element-->
                    <v-list-group
                        v-if="child.children"
                        sub-group
                        no-action
                        class="ml-n5">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <router-link
                            v-for="(grandChild, j) in child.children"
                            :key="j"
                            :to="route(grandChild.route, grandChild.filter)"
                            class="mt-n7">
                            <v-list-item class="blue-grey lighten-5" link>
                                <v-list-item-action v-if="grandChild.icon">
                                    <v-icon>{{ grandChild.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ grandChild.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                    </v-list-group>
                    <!-- End grand child element-->

                    <router-link v-else :to="route(child.route, child.filter)" class="ml-n5">
                        <v-list-item>
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list-item>
            </v-list-group>

            <router-link v-else :key="item.text" :to="route(item.route)">
                <v-list-item>
                    <v-list-item-action v-if="item.icon">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </template>
    </v-list>
</template>

<script>
export default {
    data() {
        return {
            menuItems: [
                {
                    text: "Home",
                    icon: "mdi-view-dashboard",
                    route: "/",
                },
                {
                    icon: "mdi-chevron-up",
                    "icon-alt": "mdi-chevron-down",
                    text: "Reports",
                    model: false,
                    children: [
                        {
                            icon: "mdi-cup",
                            text: "Sample Report",
                            route: "/sample-report",
                            claim: "SampleReport",
                        },
                        {
                            icon: "mdi-test-tube",
                            text: "Sample Set Test Report",
                            route: "/sample-set-test-report",
                            claim: "SampleSetTestReport",
                        },
                        {
                            icon: "mdi-test-tube",
                            text: "Lab Client Organisation Sample Sets Report",
                            route: "/lab-client-organisation-sample-sets-report",
                            claim: "LabClientOrganisationSampleSetsReport",
                        },
                    ],
                },
                {
                    icon: "mdi-chevron-up",
                    "icon-alt": "mdi-chevron-down",
                    text: "Users",
                    model: false,
                    children: [
                        {
                            icon: "mdi-test-tube",
                            text: "Lab Testers",
                            route: "/lab-testers",
                            claim: "LabTesters",
                        },
                        {
                            icon: "mdi-account-group",
                            text: "Lab Client Organisations",
                            route: "/lab-client-organisations",
                            claim: "LabClientOrganisations",
                        },
                        {
                            icon: "mdi-clipboard-account",
                            text: "Data Scientists",
                            route: "/data-scientists",
                            claim: "LabTesters",
                        }
                    ],
                },
                {
                    icon: "mdi-chevron-up",
                    "icon-alt": "mdi-chevron-down",
                    text: "Testing",
                    model: false,
                    children: [
                        {
                            icon: "mdi-test-tube",
                            text: "Lab Test Dashboard",
                            route: "/lab-test-dashboard",
                            claim: "LabTests",
                        },
                        {
                            icon: "mdi-needle",
                            text: "Samples",
                            route: "/samples",
                            claim: "Admins",
                        },
                        {
                            icon: "mdi-package-variant",
                            text: "Sample Set Management",
                            route: "/sample-set-management",
                            claim: "Admins",
                        },
                    ],
                },
                {
                    icon: "mdi-chevron-up",
                    "icon-alt": "mdi-chevron-down",
                    text: "Configuration",
                    model: false,
                    children: [
                        {
                            icon: "mdi-test-tube-empty",
                            text: "Test Management",
                            route: "/test-management",
                            claim: "Tests",
                        },
                        {
                            icon: "mdi-domain",
                            text: "Lab Test Management",
                            route: "/lab-test-management",
                            claim: "LabTests",
                        },
                        {
                            icon: "mdi-ab-testing",
                            text: "Unit",
                            route: "/units",
                            claim: "Units",
                        },
                        {
                            icon: "mdi-align-horizontal-left",
                            text: "Format",
                            route: "/formats",
                            claim: "Units",
                        },
                    ],
                },
                {
                    icon: "mdi-routes",
                    text: "All Routes",
                    route: "/master-router",
                    claim: "MasterRouter",
                    requiresWrite: false,
                },
                {
                    icon: "mdi-database-import",
                    text: "Import Samples",
                    route: "/sample-import",
                    claim: "Samples",
                    requiresWrite: true,
                },
            ],
        };
    },

    computed: {
        items() {
            return this.filterOutInvisibleMenuItems(this.menuItems);
        },
    },

    methods: {
        route(path, filter) {
            if (!path) {
                return;
            }
            let options = { path };
            if (filter) {
                options.query = { filter };
            }
            return options;
        },
        menuIcon(item) {
            item.model = item.model ? false : true;
        },
        filterOutInvisibleMenuItems(items) {
            return items
                .filter(
                    mi =>
                        // Filter out items marked as invisible:
                        (mi.visible === undefined || mi.visible()) &&
                        (mi.claim === undefined || this.hasClaim(mi.claim, mi.requiresWrite))
                ) // Filter out items that require a claim we don't have
                .map(mi => {
                    // If we have children iterate over them and filter out the invisible ones
                    if (mi.children === undefined) {
                        return mi;
                    } else {
                        let clonedObj = Object.assign({}, mi);
                        clonedObj.children = this.filterOutInvisibleMenuItems(clonedObj.children);
                        return clonedObj;
                    }
                })
                .filter(mi => {
                    // Menu items with an empty list of children are in that state because the user
                    // doesn't any of the necessary claims. We can safely remove the entire menu
                    return mi.children === undefined || mi.children.length > 0;
                });
        },
    },
};
</script>

<style type="text/css">
#bqualLab .v-list-item__action:first-child {
    margin-right: 16px;
}

#bqualLab .menu-items a {
    text-decoration: none;
    color: inherit;
}
</style>
